@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
.container {
  width: 100%;
  max-width: 1854px;
  padding: 0 15px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

.svg-container {
  display: none;
}

* {
  box-sizing: border-box;
}

body {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
@media screen and (max-width: 991px) {
  body {
    background: #ffffff;
  }
}

html {
  scroll-behavior: smooth;
}

section {
  overflow: hidden;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-family: MuseoSansCyrl-300;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
}

:focus {
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  padding-left: 40px;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
a:focus {
  outline: none;
}

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.main-wrapper {
  overflow-x: hidden;
  max-width: 100%;
}

@font-face {
  font-family: "MuseoSansCyrl-300";
  src: url("../fonts/Museo/MuseoSansCyrl-300.eot");
  src: local("☞MuseoSansCyrl-300"), local("MuseoSansCyrl-300"), url("../fonts/Museo/MuseoSansCyrl-300.eot") format("embedded-opentype"), url("../fonts/Museo/MuseoSansCyrl-300.woff2") format("woff2"), url("../fonts/Museo/MuseoSansCyrl-300.woff") format("woff"), url("../fonts/Museo/MuseoSansCyrl-300.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MuseoSansCyrl-500";
  src: url("../fonts/Museo/MuseoSansCyrl-500.eot");
  src: local("☞MuseoSansCyrl-500"), local("MuseoSansCyrl-500"), url("../fonts/Museo/MuseoSansCyrl-500.eot") format("embedded-opentype"), url("../fonts/Museo/MuseoSansCyrl-500.woff2") format("woff2"), url("../fonts/Museo/MuseoSansCyrl-500.woff") format("woff"), url("../fonts/Museo/MuseoSansCyrl-500.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MuseoSansCyrl-700";
  src: url("../fonts/Museo/MuseoSansCyrl-700.eot");
  src: local("☞MuseoSansCyrl-700"), local("MuseoSansCyrl-700"), url("../fonts/Museo/MuseoSansCyrl-700.eot") format("embedded-opentype"), url("../fonts/Museo/MuseoSansCyrl-700.woff2") format("woff2"), url("../fonts/Museo/MuseoSansCyrl-700.woff") format("woff"), url("../fonts/Museo/MuseoSansCyrl-700.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "MuseoSansCyrl-900";
  src: url("../fonts/Museo/MuseoSansCyrl-900.eot");
  src: local("☞MuseoSansCyrl-900"), local("MuseoSansCyrl-900"), url("../fonts/Museo/MuseoSansCyrl-900.eot") format("embedded-opentype"), url("../fonts/Museo/MuseoSansCyrl-900.woff2") format("woff2"), url("../fonts/Museo/MuseoSansCyrl-900.woff") format("woff"), url("../fonts/Museo/MuseoSansCyrl-900.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.error-wrapper {
  margin-top: 160px;
  box-sizing: border-box;
  display: flex;
  padding: 0 60px;
  flex-direction: column;
  position: relative;
  margin-bottom: 54px;
}
@media screen and (max-width: 1200px) {
  .error-wrapper {
    padding: 0 20px;
  }
}
.error-wrapper .blocks_back {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: -1;
}
.error-wrapper .romb-block {
  height: 603px;
  width: 1400px;
  background: #eeebeb;
  z-index: -1;
  clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
  align-self: flex-end;
}
@media screen and (max-width: 1580px) {
  .error-wrapper .romb-block {
    height: 603px;
  }
}
@media screen and (max-width: 1440px) {
  .error-wrapper .romb-block {
    height: 580px;
  }
}
@media screen and (max-width: 1200px) {
  .error-wrapper .romb-block {
    width: 70%;
  }
}
@media screen and (max-width: 768px) {
  .error-wrapper .romb-block {
    height: 365px;
  }
}
@media screen and (max-width: 480px) {
  .error-wrapper .romb-block {
    height: 365px;
  }
}
@media screen and (max-width: 380px) {
  .error-wrapper .romb-block {
    height: 365px;
  }
}
.error-wrapper .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 603px;
}
@media screen and (max-width: 1580px) {
  .error-wrapper .info {
    height: 603px;
  }
}
.error-wrapper .info .info_code {
  margin-top: 124px;
  margin-bottom: 90px;
}
.error-wrapper .info .info_text {
  color: #000;
  text-align: center;
  font-family: MuseoSansCyrl-300;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  width: 683px;
  display: flex;
}
@media screen and (max-width: 768px) {
  .error-wrapper .info .info_text {
    width: 100%;
  }
}
.error-wrapper .info .gotomian {
  border-radius: 25px;
  background: #232930;
  width: 164px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-family: MuseoSansCyrl-300;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  text-transform: uppercase;
  margin-top: 48px;
}