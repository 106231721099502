.container{
    width: 100%;
    max-width: 1854px;
    padding: 0 15px;
    margin: 0 auto;
}
a{
    text-decoration: none;
}
.svg-container{
    display: none;
}
*{
    box-sizing: border-box;
}
body{
    background: #ffffff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    @media screen and (max-width: 991px) {
        background: #ffffff;
    }
}
html{
    scroll-behavior: smooth;
}
section{
    overflow: hidden;
}
