@import 'global';
// resetCss
@import "css-reset";

// mixins
@import "mixin";

// Fonts
@import "../fonts/Museo/stylesheet";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


.error-wrapper{
    margin-top: 160px;
    box-sizing: border-box;
    display: flex;
    padding: 0 60px;
    flex-direction: column;
    position: relative;
    margin-bottom: 54px;
    @media screen and (max-width: 1200px) {
        padding: 0 20px;
    }

    .blocks_back{
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        overflow: hidden;
        left: 0;
        top: 0;
        z-index: -1;
        
    }
    .romb-block {
        height: 603px;
        width: 1400px;
        background: #eeebeb;
        z-index: -1;
        clip-path: polygon(20% 0, 100% 0, 100% 100%, 0 100%);
        align-self: flex-end;    
        @media screen and (max-width: 1580px) {
            height: 603px;
        }
        @media screen and (max-width: 1440px) {
            height: 580px;
        }
        @media screen and (max-width: 1200px) {
            width: 70%
        }
        @media screen and (max-width: 768px) {
            height: 365px;
        }
        @media screen and (max-width: 480px) {
            height: 365px;
        }
        @media screen and (max-width: 380px) {
            height: 365px;
        }
    }

    .info{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 603px;
        @media screen and (max-width: 1580px) {
            height: 603px;
        }

        .info_code{
           margin-top: 124px;
           margin-bottom: 90px;
        }
        
        .info_text{
            color: #000;
            text-align: center;
            font-family: MuseoSansCyrl-300;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 25px;
            width: 683px;
            display: flex;
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }
        .gotomian{
            border-radius: 25px;
            background: #232930;
            width: 164px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #FFF;
            text-align: center;
            font-family: MuseoSansCyrl-300;
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 30px;
            text-transform: uppercase;
            margin-top: 48px;
        }
    }
}