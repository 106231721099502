@font-face {
    font-family: 'MuseoSansCyrl-300';
    src: url('MuseoSansCyrl-300.eot');
    src: local('☞MuseoSansCyrl-300'), local('MuseoSansCyrl-300'),
        url('MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-300.woff2') format('woff2'),
        url('MuseoSansCyrl-300.woff') format('woff'),
        url('MuseoSansCyrl-300.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl-500';
    src: url('MuseoSansCyrl-500.eot');
    src: local('☞MuseoSansCyrl-500'), local('MuseoSansCyrl-500'),
        url('MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-500.woff2') format('woff2'),
        url('MuseoSansCyrl-500.woff') format('woff'),
        url('MuseoSansCyrl-500.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl-700';
    src: url('MuseoSansCyrl-700.eot');
    src: local('☞MuseoSansCyrl-700'), local('MuseoSansCyrl-700'),
        url('MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-700.woff2') format('woff2'),
        url('MuseoSansCyrl-700.woff') format('woff'),
        url('MuseoSansCyrl-700.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'MuseoSansCyrl-900';
    src: url('MuseoSansCyrl-900.eot');
    src: local('☞MuseoSansCyrl-900'), local('MuseoSansCyrl-900'),
        url('MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
        url('MuseoSansCyrl-900.woff2') format('woff2'),
        url('MuseoSansCyrl-900.woff') format('woff'),
        url('MuseoSansCyrl-900.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


